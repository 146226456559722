<template>
  <div class="layout-conatainer">
    <!-- 左侧菜单 -->
    <div class="left-menu-container">
      <!-- 菜单内容 -->
      <div class="menu-box">
        <!-- 标题 -->
        <div class="menu-title" @click="openServiceConfig">
          <p class="text">蓝牙控制管理系统</p>
        </div>

        <!-- 列表内容 -->
        <div class="menu-container ml-scrollbar">
          <menu-box
            :userMenuData="menuData"
            ref="menuRef"
            @onItemChange="onItemChange"
          ></menu-box>
        </div>
      </div>
    </div>

    <!-- 右侧展示内容 -->
    <div class="right-iframe-container">
      <!-- 顶部内容 -->
      <div class="ifarme-header">
        <header-box ref="headerRef"></header-box>
      </div>

      <!-- 路由框架 -->
      <div class="router-ifarme ml-scrollbar group-ifarme">
        <router-view ref="routerViewRef" v-if="isRefresh"></router-view>
        <div class="bg-loading" v-loading="true" v-else></div>
      </div>
    </div>
  </div>
</template>
<script setup>
import HeaderBox from "./components/header.vue";
import MenuBox from "./components/menu.vue";
import { useRouter } from "vue-router";

import {
  getCurrentInstance,
  ref,
  computed,
  onMounted,
  nextTick,
  onBeforeMount,
  watch,
} from "vue";
import { exportMenusData } from "@/router/pages";

const router = useRouter();

const { proxy } = getCurrentInstance();

const { mainStore, dataStore } = proxy.$usePiniaModule();

// ref
const routerViewRef = ref();

/* 详情页导航栏 */
// 路由信息
const currRoute = computed(() => proxy.$route.meta);
watch(
  () => currRoute.value,
  (nVal) => {
    const { parentTitles = [] } = nVal;
    const level = parentTitles.length;
    showRoutePageTitle.value = level <= 2;
  },
  {
    deep: true,
  }
);

// 显示 route 页面 title
const showRoutePageTitle = ref(true);
// 当前路由标题
const currRouteTitle = computed(() => {
  const { customLayOutTitle } = mainStore;

  // 自定义 标题字符
  if (customLayOutTitle) {
    return customLayOutTitle;
  }
  // console.log(currRoute.value);

  const metaTitle = currRoute.value;
  return metaTitle.title;
});

// 是否刷新routerView
const isRefresh = computed(() => dataStore.globalViewRefresh);

/* ml-tabs */
/* 当前页面路由名 */
const currentRouteName = computed(() => proxy.$route.name);
/* 当前页面下的 选项卡缓存名称 */
const changeTabStoreName = computed(
  () => `${currentRouteName.value}__changeTab`
);
// 清除选项卡本地缓存
const clearStoreData = () => {
  proxy.$setItem(changeTabStoreName.value, "");
};

// 监听 菜单item
const onItemChange = () => {
  clearStoreData();
  routerViewRef.value = true;
  // console.log("监听 菜单item，清除指定本地缓存");
};

/* 菜单操作 START */
// 标准 菜单数据 提供指引项 + 渲染
const menuData = computed(() => mainStore.leftMenuData);
/* 本地测试使用 */
// const menuData = computed(() => {
//   console.log("菜单操作 本地测试使用");
//   // 展示菜单数据
//   const result = proxy.$createMenutDatas(exportMenusData, 0, [], 0, 0, {
//     dataType: "show-menu",
//   });

//   // console.log(result);
//   return result;
// });

//本地数据 导入菜单数据
const useMenusData = computed(() => {
  // 使用权限数据
  return proxy.$createMenutDatas(exportMenusData, 0, [], 0, 0, {
    dataType: "use-auth-menu",
  });
});

// 监听菜单的变化
// watch(
//   () => menuData.value,
//   (nVal) => {
//     nextTick(() => {
//       setRouteFirstPage(nVal);
//     });
//   }
// );

// ref
const menuRef = ref();
const headerRef = ref();

// 恢复默认第一页 （登录）
const setRouteFirstPage = async (list) => {
  setTimeout(async () => {
    // console.log(list);
    // console.log(mainStore.menuHrefs);
    // console.log(mainStore.initRedirect);

    // 初始菜单 定位
    // 重置
    mainStore.initRedirect = false;

    // 取第一位值
    const firstHref = await findNextPath(0);

    // console.log(firstHref);
    // console.log(proxy.$route);
    proxy.$router.push(firstHref);

    setTimeout(() => {
      if (menuRef.value) {
        //指引 定向左侧菜单选中效果
        menuRef.value.initPathIdItem(firstHref, menuData.value);
      }
    });
  });
};
// 查找下一页
const findNextPath = (index) => {
  return new Promise((resolve, reject) => {
    const href = mainStore.menuHrefs[index];

    // console.log(mainStore.menuHrefs);

    if (href) {
      // 跳过 权限页面 路由
      const jumpAuthPaths = ["/", "/404", "/login", "/main"];

      // 判断
      if (jumpAuthPaths.includes(href)) {
        findNextPath((index += 1));
      }
    }

    resolve(href || "/");
  });
};
/* 菜单操作 END */

// ******************************************************************************
// 查询 START
// ******************************************************************************

// ******************************************************************************
// 查询 END
// ******************************************************************************

/* 服务配置 */
const openServiceConfig = () => {
  headerRef.value.onChangeCount();
};

/* 菜单初始操作 */
// 导入菜单数据
const importMenusData = (list) => {
  if (!list.length) {
    return list;
  }

  // 遍历
  for (let i = 0; i < list.length; i++) {
    const item = list[i];

    const { children } = item;

    const paramNames = [
      "status",
      "spread",
      "href",
      "icon",
      "name",
      "actionScope",
      "code",
      "children",
      "parentId",
    ];

    for (let key in item) {
      if (!paramNames.includes(key)) {
        delete item[key];
      }
    }

    item.children = importMenusData(children);
  }

  return list;
};

// 初始系统菜单数据以及权限
const initSystemMenuAuths = async () => {
  // 处理菜单权限
  // 正式调用 更新菜单
  await proxy.$updateMenuAuths();

  // console.log(mainStore.initRedirect)
  // 初始重定向
  if (mainStore.initRedirect) {
    // 未指定 路由地址
    setRouteFirstPage(menuData.value);
  } else {
    // 无需重定向
    const routePath = proxy.$route.path;

    // 窗口 已指定 路由地址
    // 初始主页
    if (routePath == "/") {
      // 未指定 路由地址
      await setRouteFirstPage(menuData.value);
      return;
    }

    // console.log(menuData.value);
    // 初始 定向路由item 数据
    if (menuRef.value) {
      menuRef.value.initItemDirect(menuData.value);
    }
  }
};

// 初始
const init = async () => {
  // 查询用户信息
  await proxy.$getCurrLoginInfo();
};

//退出重新登录+路径直接跳转 进入 初始
onMounted(async () => {
  await init();

  // 动态 生成 导入菜单数据
  // const authMenuData = proxy
  //   .$removeDecontextAttr(useMenusData.value)
  //   .map((d) => ({ ...d, parentId: 0 }));
  // importMenusData(authMenuData);
  // console.log(authMenuData);

  initSystemMenuAuths();
});

// 实例挂载之前
onBeforeMount(() => {
  const token = proxy.$getStoreAgeToken();
  // 判断token
  if (!token) {
    // 转向 登录
    proxy.$router.push("/login");
  }
});
</script>

<style lang="scss" scoped>
.layout-conatainer {
  width: 100%;
  display: grid;
  grid-template-columns: 220px 1fr;
  height: 100vh;
  opacity: 0;
  animation: initLayout 300ms linear 200ms forwards;

  @keyframes initLayout {
    to {
      opacity: 1;
    }
  }

  .left-menu-container {
    // background-color: #222530;
    background-color: #222834;
    overflow: hidden;

    > .menu-box {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-rows: 60px 1fr;

      .menu-title {
        width: 100%;
        height: 100%;
        line-height: 60px;
        color: #fff;
        text-align: center;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;
        // padding-left: 16px;
        margin: auto;
        box-sizing: border-box;
        cursor: pointer;
        // padding-bottom: 16px;
        -webkit-user-select: none;

        .logo {
          width: 36px;
          height: 36px;
          margin-right: 8px;
          border-radius: 5px;
        }
        .text {
          font-weight: 700;
          font-size: 18px;
          width: 100%;
          text-align: center;
          font-family: "Arial Negreta", "Arial Normal", "Arial";
        }
      }

      .home-box {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        color: #9fa6bc;
        font-size: 13px;
        cursor: pointer;
        padding-left: 32px;
        transition: 300ms;
        font-weight: 700;

        &:hover {
          color: white;
        }

        .home-icon {
          height: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;

          .icon-pwatch {
            margin-right: 5px;
          }
        }
      }

      .menu-container {
        height: 100%;
        overflow-y: auto;
      }
    }
  }

  .right-iframe-container {
    display: grid;
    grid-template-rows: 60px 1fr;
    overflow: hidden;

    .ifarme-header {
      padding: 0 15px;
      // border-bottom: 1px solid #2228341a;
    }

    .router-ifarme {
      background-color: rgba(244, 245, 251, 1);
      height: 100%;

      // 主页
      > .manage-box {
        > .customer-container {
          // 主页表格
        }
      }
    }
  }
}
</style>

<style lang="scss">
.layout-conatainer {
  .right-iframe-container {
    // 框架
    > .router-ifarme {
      // 常规
      .manage-box {
        > .filter-box {
          padding: 10px 15px !important;
          padding-bottom: 0 !important;

          .el-form-item {
            margin-bottom: 10px;
          }
        }

        // 内容区
        > .panel-main-container {
          > .customer-container {
            // 组合式
            > .table-content {
              border-radius: 6px;
              overflow: hidden;
              display: grid;
              grid-template-rows: 1fr;

              &.group-2 {
                grid-template-rows: auto 1fr;
              }

              .table-content__tool {
                padding: 0 15px;
                margin-bottom: 10px;
                text-align: right;
              }
            }

            // 表格
            .__USE__TABLE_CONTAINER {
              box-sizing: border-box;
              // padding: 0 15px;
              margin: 0 15px;
              width: calc(100% - 30px);

              .el-table__inner-wrapper {
                &::before {
                  background-color: transparent;
                }
              }

              .warpper-table-content {
                background-color: white;
                padding: 0 15px;
                border: 1px solid #ebeef5;
                border-radius: 6px;
                box-sizing: border-box;
              }
            }

            > .table-content {
              > .table-container {
                @extend .__USE__TABLE_CONTAINER;
              }
            }
          }
        }
      }

      // 滚动条
      &.scroll-view-ifarme {
        overflow-y: auto;
        ::v-deep(.manage-box) {
          height: 100%;
          > .customer-container {
            > .table-content {
              > .table-container {
              }
            }
          }
        }
      }

      // 100%高度 分组式布局
      &.group-ifarme {
        overflow: hidden;
        display: grid;
        grid-template-rows: 1fr auto;
        height: 100%;
        .manage-box {
          height: 100%;
          display: grid;
          grid-template-rows: auto 1fr;
          overflow: hidden;

          > .filter-box {
            padding: 18px 0;
            padding-bottom: 0;

            .filter-form {
              .el-form-item {
                margin-right: 20px;
                &:nth-last-child(1) {
                  margin-right: 0;
                }
              }
            }
          }

          > .panel-main-container {
            > .customer-container {
              height: 100%;
              overflow: hidden;

              > .table-content {
                height: 100%;
                overflow: hidden;

                .table-content__tool {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                }

                > .table-container {
                  display: grid;
                  grid-template-rows: 1fr auto;
                  height: 100%;
                  overflow: hidden;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
