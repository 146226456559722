<template>
  <!-- 树形组合 -->
  <!-- 标题 -->

  <div
    class="children-item-title"
    v-if="$isEmpty(currChildItem.display) || currChildItem.display"
    :class="{ 'is-active': currChildItem.id == changeId }"
    @click="itemHandleClick(currChildItem)"
  >
    <!-- 操作图标 -->
    <div
      class="action-icon"
      :class="{ 'is-icon': currChildItem.icon }"
      @click="itemHandleClick(currChildItem)"
    >
      <div class="lattice">
        <div class="menu-item-icon">
          <!-- 图标 -->
          <i :class="`iconfont ${currChildItem.icon}`"></i>
        </div>
      </div>
    </div>

    <!-- 右侧组合 -->
    <div class="right-group">
      <!-- 标签名 -->
      <div
        class="title oneLineOver"
        :class="{
          item_padding:
            !currChildItem.children.length && currChildItem.level == 2,
        }"
      >
        {{ currChildItem.name }}
      </div>

      <!-- 折叠图标 -->
      <div class="lattice" v-if="currChildItem.children.length">
        <!-- 图标 -->
        <el-icon
          @click.stop="accordionSwitch(currChildItem)"
          v-if="currChildItem.children.length"
          :class="{ 'is-rotate': currChildItem.opend }"
          :size="16"
          class="icon-rotate"
          ><CaretRight
        /></el-icon>
        <!-- <i
          class="iconfont icon-rotate icon-arrow-right"
          @click.stop="accordionSwitch(currChildItem)"
          v-if="currChildItem.children.length"
          :class="{ 'is-rotate': currChildItem.opend }"
        ></i> -->
      </div>
    </div>
  </div>

  <!-- 3级 子级内容 -->
  <div
    class="child-data"
    v-if="currChildItem.children.length"
    :style="{
      height: `${
        currChildItem.opend ? currChildItem.opendNum * 45 + 'px' : '0px'
      }`,
    }"
  >
    <!-- 选项 -->
    <div
      v-for="thItem in currChildItem.children"
      :key="'children-item' + thItem.id"
    >
      <slot :data="thItem">
        <div
          class="child-data-item"
          @click="changeMenuItem(thItem)"
          v-if="$isEmpty(thItem.display) || thItem.display"
        >
          <div class="child-data-item-title">
            <div class="title" :class="{ 'is-active': thItem.id == changeId }">
              {{ thItem.name }}
            </div>
          </div>
        </div>
      </slot>
    </div>
  </div>

  <div v-else></div>
</template>

<script setup>
import { computed, ref, watch } from "vue";

const emits = defineEmits(["update:modelValue", "onChangeMenuItemChild"]);
const props = defineProps({
  // 菜单数据
  child: {
    type: Object,
    default: () => ({}),
  },
  menuId: {
    type: [Number, String],
    default: "",
  },
});

const currChildItem = ref(props.child);
watch(
  () => props.child,
  (nVal) => {
    currChildItem.value = nVal;
  },
  {
    deep: true,
  }
);

// 当前选中的 菜单
const changeId = ref(props.menuId);
watch(
  () => props.menuId,
  (nVal) => {
    changeId.value = nVal;
  }
);

// 展开/收起
const accordionSwitch = (item) => {
  item.opend = !item.opend;
};

const changeMenuItem = (item) => {
  emits("onChangeMenuItemChild", item);
};

// 操作点击
const itemHandleClick = (item) => {
  // console.log(item);
  // 存在子级
  if (item.children.length) {
    accordionSwitch(item);
  } else {
    // 页面
    changeMenuItem(item);
  }
};
</script>

<style lang="scss" scoped>
.child-data {
  .children-item-title {
    .right-group {
      padding-left: 25px;
    }
  }
}

.children-item-title {
  display: grid;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  color: white;
  grid-template-columns: auto 1fr;
  align-items: center;
  color: #b9c7dd;
  height: 40px;
  line-height: 40px;
  transition: 300ms;
  cursor: pointer;
  user-select: none;
  padding-left: 10px;
  // padding-left: 0px;

  &:hover {
    color: white !important;
    background-color: rgb(210 210 210 / 4%) !important;

    .right-group {
      .title {
        color: white !important;
      }
    }
    // background-color: rgb(210 210 210 / 4%) !important;
    // background-color: rgba(35, 44, 50, 1) !important;
  }

  &.is-active {
    // color: white;
    background-color: rgb(210 210 210 / 9%) !important;
    // background-color: rgba(35, 44, 50, 1) !important;
    .right-group {
      .title {
        color: white !important;
      }
    }
  }

  .right-group {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    padding-right: 15px;

    .title {
      color: #8ca1af;
      transition: 600ms;
      font-weight: 700;
    }

    .lattice {
      display: flex;
      align-items: center;

      .icon-rotate {
        transition: 300ms;
        transform: rotate(90deg);
        font-size: 12px;
        &.is-rotate {
          transform: rotate(270deg);
        }
      }
    }
  }

  .title {
    width: 100%;
    font-size: 14px;
    // padding-left: 10px;
    box-sizing: border-box;

    // 子集内边距
    &.item_padding {
      padding-left: 24px;
    }
  }
  .action-icon {
    text-align: center;
    cursor: pointer;
    width: auto;
    // margin-right: 6px;
    margin-right: 0;
    min-width: 14px;
    &.is-icon {
      width: 39px;
    }

    // 格子
    .lattice {
      display: flex;
      width: 100%;
      align-items: center;
      .menu-item-icon {
        font-size: 14px;
        margin-left: 0px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

// 3级
.child-data {
  // padding-left: 24px;
  overflow: hidden;
  transition: height 200ms;

  .child-data-item {
    padding: 8px 0;
    padding-left: 15px;
    .child-data-item-title {
      .title {
        // color: #9fa6bc;
        color: #8ca1af;
        font-size: 14px;
        transition: 300ms;
        font-weight: initial;

        &:hover,
        &.is-active {
          // color: white;
        }
      }
    }
  }

  .children-item-title {
    .title {
      font-weight: initial;
    }
  }
}
</style>
