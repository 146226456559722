<template>
  <!-- 2级 主项 子级内容 -->
  <div class="master-children" v-if="currItem.children.length">
    <!-- 选项 -->
    <div
      class="children-item"
      v-for="child in currItem.children"
      :key="'children-item' + child.id"
    >
      <menu-item-child
        :menuId="changeId"
        :child="child"
        @onChangeMenuItemChild="changeMenuItem"
      >
        <template #default="{ data }">
          <menu-item-child
            :menuId="changeId"
            :child="data"
            @onChangeMenuItemChild="changeMenuItem"
          >
          </menu-item-child>
        </template>
      </menu-item-child>
    </div>
  </div>
</template>

<script setup>
import menuItemChild from "./menu-item-child.vue";

import { ref, watch } from "vue";

const emits = defineEmits(["update:modelValue", "onChangeMenuItem"]);
const props = defineProps({
  // 菜单数据
  currItem: {
    type: Object,
    default: () => ({}),
  },
  modelValue: {
    type: [String, Number],
    default: "",
  },
});

const changeId = ref(props.modelValue);
watch(
  () => props.modelValue,
  (nVal) => {
    changeId.value = nVal;
  }
);

const changeMenuItem = (item) => {
  emits("onChangeMenuItem", item);
};
</script>

<style lang="scss" scoped>
// 主项 内容
.master-children {
  font-size: 14px;
  color: white;
  // padding-left: 15px;
}
</style>
