<template>
  <div class="menu-box">
    <menu-box
      ref="menuBoxRef"
      :menus="useMenuData"
      v-model="changeItem"
      :isMaterTheme="false"
      @onItemChange="onItemChange"
    >
    </menu-box>
  </div>
</template>

<script setup>
import menuBox from "./components/menu-box.vue";

import { ref, computed, getCurrentInstance, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { exportMenusData } from "@/router/pages";

const emits = defineEmits(["onItemChange"]);

const props = defineProps({
  userMenuData: {
    type: Array,
    default: () => [],
  },
});

const { proxy } = getCurrentInstance();

const router = useRouter();
const route = useRoute();

// ref
const menuBoxRef = ref();

// 使用 计算后的菜单数据
const useMenuData = computed(() => findOpenResult(props.userMenuData));

// 处理菜单数据
/**
 * 1.查找子级
 * 2.查找子级展开
 * 3.计算子级展开显示标签的数量
 * 4.return
 */
const findOpenNum = (list) => {
  return list.reduce((add, curr) => {
    const childs = curr.children || [];
    // add 当前数量 findOpenNum(curr.opend ? childs : []) 子级计算数量
    return add + findOpenNum(curr.opend ? childs : []);
  }, list.length);
};
const findOpenResult = (list) => {
  return list.map((item) => {
    item.children = findOpenResult(item.children || []);
    item.opendNum = findOpenNum(item.children || []);
    return item;
  });
};

// 菜单item发生改变
const onItemChange = (item) => {
  if (item) {
    // console.log("选择跳转");
    // console.log(item);
    router.push({ path: item.href, query: item.routeParams || {} });
    // console.log("选中前", item.id);
    // 赋值选中菜单 item
    changeItem.value = item;
    // // 选中菜单子级item
    // menuBoxRef.value.initChange(item);
    emits("onItemChange", item);
  }
};

// 选择菜单选项
const changeItem = ref({});

// 直接访问路由地址 查询路径ID 并赋值
const initPathIdItem = (path, menus) => {
  setTimeout(() => {
    // console.log(path);
    // console.log(menus);

    // 等待真实数据 ---------------》
    // 使用的菜单数据
    const useMenuts = menus;

    // 查找当前path
    const use_item = findIdResult(path, useMenuts);
    // console.log(use_item);
    // console.log(path, useMenuts);

    // 通过的路由
    const passRoutes = proxy.$notJoinAuthMenus(exportMenusData);
    const passRoutePaths = passRoutes.map((d) => d.path);

    // 跳过权限的路由地址
    const jumpAuthPaths = ["/", "/404", "/login", "/main"];
    // console.log(path);
    // console.log(use_item);
    // console.log(passRoutePaths);
    // console.log(passRoutePaths.includes(path));

    // 未能找到使用 权限菜单上的数据
    if (
      !use_item &&
      !jumpAuthPaths.includes(path) &&
      !passRoutePaths.includes(path)
    ) {
      // router.push("/404");
      return;
    }

    // 继续寻找
    const { currItem = {}, parent = {} } = use_item || {};

    // 在当前 预览菜单数据中寻找item
    const preview_item = findIdResult(currItem.href, useMenuData.value);

    // 是否存在
    let result = preview_item ? currItem : parent;

    // 不存在
    if (!preview_item) {
      findIdResult(parent.href, useMenuData.value);
    }

    // 为筛选到有效的 菜单item
    if (!result.id) {
      /* 根据本地静态数据 逆推 动态的数据 */
      // 本地处理 筛选到的 菜单item
      const local_menu_item =
        findIdResult(
          path,
          proxy.$createMenutDatas(exportMenusData, 0, [], 0, 0, {
            dataType: "use-auth-menu",
          })
        ) || {};

      // 筛选结果
      const changeResult =
        findIdResult((local_menu_item.parent || {}).href, useMenuData.value) ||
        {};
      // console.log(changeResult);
      // 赋值
      result = changeResult.currItem || {};
    }
    // console.log(useMenuData.value);
    // console.log(result);
    // 赋值选中菜单 item
    changeItem.value = result;
  });
};

// 递归
const findIdResult = function (path, list, parent) {
  // 查询到多个相同路径的菜单， 文件管理模块
  const findItems = list.filter((item) => item.href == path);
  let findItem = {};
  if (findItems.length > 1) {
    // 筛选出指定的 存储文件项
    // 路由参数
    const routeParams = route.query;
    findItem = findItems.find(
      (d) => d.routeParams.storageBucketId == routeParams.storageBucketId
    );
  } else {
    findItem = findItems[0];
  }

  // 找到
  if (findItem) {
    return {
      currItem: findItem,
      parent,
    };
  } else {
    for (let i = 0; i < list.length; i++) {
      const item = list[i];
      const children = item.children || [];

      const result = findIdResult(path, children, item);
      if (result) {
        item.opend = true;
        return result;
      }
    }
  }
};

// 初始 定位 选中菜单item
const initItemDirect = (list) => {
  // 计算子级数量后
  const data = findOpenResult(list);
  initPathIdItem(route.path, data);
};

// onMounted(() => {
//   console.log(useMenuData.value);
// });

defineExpose({
  findIdResult,
  initItemDirect,
  onItemChange,
  initPathIdItem,
});
</script>

<style lang="scss" scoped>
.menu-box {
  // 主项
}
</style>
