<template>
  <!-- 主标题模式 -->
  <div v-if="isMaterTheme">
    <div v-for="(currItem, index) in menusList" :key="'menu-item' + index">
      <!-- 主项 父子级组  -->
      <div class="master-item parent-child">
        <!-- 1级 主标题 大主题 -->
        <!-- <div
          class="master-title"
          v-if="
            !$isEmpty(currItem.display) &&
            currItem.display &&
            currItem.isMaterTheme
          "
        >
          <span>
            {{ currItem.title }}
          </span>
        </div> -->

        <menu-item
          v-model="changeId"
          @onChangeMenuItem="changeMenuItem"
          :currItem="{ children: currItem.children }"
        ></menu-item>
      </div>
    </div>
  </div>

  <!-- 普通模式 -->
  <div v-else>
    <menu-item
      v-model="changeId"
      @onChangeMenuItem="changeMenuItem"
      :currItem="{ children: menusList }"
    ></menu-item>
  </div>
</template>

<script setup>
import menuItem from "./menu-item.vue";
import { ref, watch, computed } from "vue";

const emits = defineEmits(["update:modelValue", "onItemChange"]);
const props = defineProps({
  // 菜单数据
  menus: {
    type: Array,
    default: () => [],
  },
  modelValue: {
    type: Object,
    default: () => ({}),
  },
  // 主标题模式
  isMaterTheme: {
    type: Boolean,
    default: false,
  },
});

// 当前菜单选项
const menusList = computed(() => props.menus);

// 选择选择
const changeItem = ref(props.modelValue);

// id
const changeId = ref();

watch(
  () => props.modelValue,
  (nVal) => {
    initChange(nVal);
  },
  {
    deep: true,
  }
);

//初始选中
const initChange = (nVal) => {
  changeItem.value = nVal;
  // 选中菜单ID
  changeId.value = nVal.id;
  // console.log(nVal);
  console.log("选中后", changeId.value);
};

// 选择菜单item
const changeMenuItem = (item) => {
  // console.log(item);
  if (item.href) {
    emits("update:modelValue", changeItem.value);
    emits("onItemChange", item);
  }
};

defineExpose({
  initChange,
});
</script>

<style lang="scss" scoped>
// 主项
.master-item {
  cursor: pointer;
  user-select: none;
  padding-top: 0.1px;

  &.parent-child {
    // 标题
    .master-title {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      padding: 10px 0;
      padding-top: 0;
      // padding-left: 35px;
      padding-left: 15px;
      box-sizing: border-box;
      grid-template-columns: 30px 1fr;
      transition: 300ms;
      margin-top: 12px;
      font-weight: 700;
      font-size: 10px;
      color: #627b8c;
      font-family: "Arial Normal", "Arial";

      &:hover {
        color: white;
      }
    }
  }
}
</style>
