<template>
  <div class="header-container">
    <!-- left -->
    <div class="left-box">
      <!-- 面包屑 -->
      <div class="breadcrumb-box">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item
            v-for="(item, index) in breadcrumbList"
            :key="'breadcrumb' + index"
            @click="handleClickBreadcrumb(index)"
          >
            <span :class="`breadcrumb-label label${index}`">{{ item }}</span>
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <!-- 当前路由标题 -->
      <!-- <div class="curr-route-title">
        {{ currRouteTitle }}
      </div> -->
    </div>

    <!-- right -->
    <div class="right-box">
      <!-- 用户信息 -->
      <div class="user-info" @click="openDownPopup">
        <el-dropdown
          trigger="click"
          ref="dropdownRef"
          popper-class="header-info-popup"
        >
          <div class="avator">
            <head-sculpture
              size="36"
              :src="currLoginUserInfo.headPortrait"
            ></head-sculpture>
          </div>
          <template #dropdown>
            <div class="dropdown-detail">
              <div class="top">
                <div class="avator">
                  <head-sculpture
                    size="50"
                    @handleClick="uploadAvator"
                    :src="currLoginUserInfo.headPortrait"
                  ></head-sculpture>
                </div>
                <p class="user-name">
                  <!-- {{ userName || "用户名" }} -->
                  <el-input
                    type="text"
                    autofocus="true"
                    v-model="userName"
                    @change="changeName"
                  ></el-input>
                </p>
              </div>

              <div class="tool-list">
                <div class="tool-item" @click="openUpdatePasswordDialog">
                  <i class="iconfont icon-edit tool-icon" :size="18"></i>
                  <span>修改密码</span>
                </div>
              </div>

              <div class="bottom">
                <!-- 退出 -->
                <div class="login-out" @click="loginOut">
                  <el-image
                    class="login-out-icon"
                    :src="loginOutIcon"
                  ></el-image>
                  <span class="text">退出登录</span>
                </div>

                <div class="descrble">Privacy policy · Terms · Cookies</div>
              </div>
            </div>
          </template>
        </el-dropdown>

        <div class="show-info-warpper">
          <p class="nickName">
            {{ currLoginUserInfo.name || "用户名" }}
          </p>
          <!-- <p class="companyName" v-if="currLoginUserInfo.companyName">
            {{ currLoginUserInfo.companyName }}
          </p> -->
        </div>
      </div>
    </div>
  </div>

  <!-- 修改密码 -->
  <tool-dialog
    size="normal"
    ref="updatePwdDialogRef"
    title="修改密码"
    @confirm="updatePwdDialogConfirm"
    @dialogClosed="resetUpdatePwdForm"
  >
    <ml-form
      :model="formData"
      :rules="formRules"
      class="custom-form ml-scrollbar hide-scrollbar"
      ref="updatePwdFormRef"
      size="large"
      label-position="top"
      label-width="120px"
      :showBtn="false"
    >
      <el-form-item prop="oldPassword" label="旧密码">
        <ml-input
          type="password"
          placeholder="请输入旧密码"
          v-model="formData.oldPassword"
        ></ml-input>
      </el-form-item>
      <el-form-item prop="newPassword" label="新密码">
        <ml-input
          type="password"
          placeholder="请输入新密码"
          v-model="formData.newPassword"
        ></ml-input>
      </el-form-item>
      <el-form-item prop="againPassword" label="确认新密码">
        <ml-input
          type="password"
          placeholder="请输入确认新密码"
          v-model="formData.againPassword"
        ></ml-input>
      </el-form-item>
    </ml-form>
  </tool-dialog>
</template>

<script setup>
import { reactive, ref, computed, watch, nextTick } from "vue";
import { usePageModule } from "@/utils/mixin";
import { STORE_SERVICE_URL_NAME } from "@/utils/storageTables";

const { currLoginUserInfo, mainStore, proxy } = usePageModule();

// 路由信息
const currRoute = computed(() => proxy.$route);
const currRoutePath = computed(() => proxy.$route.path);
watch(
  () => currRoutePath.value,
  (nVal) => {
    headerChoose.value = nVal;
  }
);

// 当前理由meta信息
const currRouteMeta = computed(() => currRoute.value.meta);
// 面包屑
const breadcrumbList = computed(() => {
  const { parentTitles = [] } = currRouteMeta.value;
  const level = parentTitles.length;

  // 子级
  if (level >= 2) {
    return parentTitles;
  } else {
    return [...parentTitles, currRouteTitle.value];
  }
});
// 当前路由标题
const currRouteTitle = computed(() => currRouteMeta.value.title);

// 图片
const loginOutIcon = require("@/assets/images/login-out.png");

//选中
const headerChoose = ref(currRoutePath.value);

// 选择面包屑
const handleClickBreadcrumb = (index) => {
  if (index) {
    proxy.$router.go(-1);
  }
};

/* 修改用户名 */
const userName = ref();
watch(
  () => currLoginUserInfo.value.name,
  (nVal) => {
    userName.value = nVal;
  }
);
const changeName = async () => {
  const params = {
    name: userName.value,
    id: currLoginUserInfo.value.id,
  };

  // mainStore.userInfo.name = userName.value;
  try {
    await proxy.$storeDispatch("fetchModifySystemUser", { params });
    proxy.$successMsg("修改成功");
    setTimeout(() => {
      // proxy.$router.go(0);
      mainStore.userInfo.name = userName.value;
    });
  } catch (error) {}
};

/* 修改密码 */
// 校验常规密码
const testNormalPwd = (rule, value, callback) => {
  const len = (value || "").toString().length;
  if ((value && len < 6) || len > 20) {
    callback(new Error("请输入6-20位的密码"));
  } else {
    callback();
  }
};
// 验证密码是否一致
const testPwdIdentical = (rule, value, callback) => {
  if (formData.newPassword && formData.againPassword) {
    if (formData.newPassword != formData.againPassword) {
      callback(new Error("两次密码不一致"));
    } else {
      callback();
    }
  } else {
    callback();
  }
};

// 修改密码
// ref
const updatePwdDialogRef = ref();
const updatePwdFormRef = ref();
// 数据
const defaultForm = () => ({
  oldPassword: "",
  newPassword: "",
  againPassword: "",
});

const formData = reactive(defaultForm());
const formRules = computed(() => {
  const rules = {
    oldPassword: [],
    newPassword: [],
    againPassword: [],
  };

  // 密码基本套件
  const pwdRules = [
    {
      validator: testNormalPwd,
    },
    {
      validator: testPwdIdentical,
    },
  ];
  rules.oldPassword = [
    {
      validator: testNormalPwd,
    },
  ];
  rules.newPassword = pwdRules;
  rules.againPassword = pwdRules;
  // if (!formData.id) {
  rules.oldPassword.unshift({
    required: true,
    trigger: "change",
    message: "请先输入旧密码",
  });
  rules.newPassword.unshift({
    required: true,
    trigger: "change",
    message: "请先输入新密码",
  });
  rules.againPassword.unshift({
    message: "请先输入确认新密码",
    trigger: "change",
    required: true,
  });
  // }

  return rules;
});

// 重置 修改密码弹窗
const resetUpdatePwdForm = () => {
  proxy.$updateParams(formData, defaultForm());
  nextTick(() => {
    // 判断是否dom更新完毕，获取的dom不是null
    if (!proxy.$isEmpty(updatePwdFormRef.value)) {
      updatePwdFormRef.value.resetFields();
    }
  });
};

// 打开窗口
const openUpdatePasswordDialog = () => {
  closeDownPopup();
  updatePwdDialogRef.value.openDialog();
};
// 确认
const updatePwdDialogConfirm = async (close) => {
  const testRes = await proxy.$testForm(close, updatePwdFormRef.value);
  if (!testRes) {
    return;
  }
  const { newPassword, oldPassword } = formData;
  const param = {
    newPassword,
    oldPassword,
  };
  // console.log(param);
  try {
    await proxy.$storeDispatch("fetchModifyUserPassword", param);
    proxy.$successMsg("修改成功");
    close();
    updatePwdDialogRef.value.closeDialog();
    proxy.$delayHandle(loginOut, 1500);
  } catch (error) {
    close();
  }
};

// 退出登录
const loginOut = async () => {
  try {
    // await proxy.$storeDispatch("fetchLogout");
    proxy.$userLoginExit();
  } catch (error) {}
};
// 上传头像
const uploadAvator = proxy.$bypass(async () => {
  try {
    const result = await proxy.$changeImageFile({
      folder: "HEAD_PORTRAIT",
    });

    // console.log(result);
    if (!result.length) {
      return;
    }

    const [files = {}] = result;
    const headPortrait = files.relativePath || "";
    const params = {
      headPortrait,
      id: currLoginUserInfo.value.id,
    };

    await proxy.$storeDispatch("fetchModifySystemUser", { params });
    proxy.$successMsg("修改成功");
    setTimeout(() => {
      // proxy.$router.go(0);
      mainStore.userInfo.headPortrait = headPortrait;
    });
  } catch (error) {
    console.log(error);
  }
});

// 下拉
const dropdownRef = ref();
//打开下拉
const openDownPopup = () => {
  dropdownRef.value.handleOpen();
};
//关闭下拉
const closeDownPopup = () => {
  dropdownRef.value.handleClose();
};

// defineExpose({
//   onChangeCount,
// });
</script>

<style lang="scss" scoped>
.header-container {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: space-between;
  align-items: center;

  .left-box {
    .curr-route-title {
      font-weight: bold;
      font-size: 17px;
      margin-top: 4px;
    }

    .breadcrumb-label {
      color: gray;
      &.label1 {
        color: black;
      }
    }
  }
  .right-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    .tool-table {
      display: flex;
      flex-direction: row;
      align-items: center;

      .tool-icon {
        padding-right: 20px;
        color: #474747 !important;
        cursor: pointer;
        font-size: 20px;
      }
    }

    .user-info {
      padding-left: 10px;
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      font-size: 14px;
      cursor: pointer;
      .avator {
        width: 36px;
        height: 36px;
        margin-right: 10px;
        border-radius: 50px;
        overflow: hidden;
      }
      .nickName {
        font-weight: 600;
      }
      .companyName {
        font-size: 12px;
        color: black;
      }
    }
  }
}

// 下拉用户信息
.dropdown-detail {
  padding: 15px 0;
  border-radius: 50px;
  width: 250px;

  > div {
    border-bottom: 1px solid #9e9e9e30;

    &:nth-last-child(1) {
      border: none;
    }
  }

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;

    .avator {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      overflow: hidden;
    }
    .user-name {
      line-height: 45px;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .tool-list {
    padding: 0 10px;
    padding-bottom: 15px;
    .tool-item {
      font-size: 14px;
      display: grid;
      flex-direction: row;
      align-items: center;
      grid-template-columns: auto 1fr;
      margin-top: 15px;
      cursor: pointer;

      .manual-icon {
        margin-right: 6px;
        width: 19px;
      }
      .tool-icon {
        margin-right: 5px;
        font-weight: 700;
        width: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        color: #474747 !important;
      }
    }
  }

  .bottom {
    padding: 15px 10px;
    padding-bottom: 0;
    .login-out {
      height: 36px;
      text-align: center;
      font-size: 14px;
      line-height: 36px;
      border-radius: 8px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      transition: 300ms;
      color: #31374a;
      background-color: #f5f7fa;
      border: 1px solid #e3e6ed;
      box-sizing: border-box;

      &:hover {
        background-color: #e3e6ed;
      }

      .login-out-icon {
        width: 16px;
        display: flex;
        margin-right: 10px;
      }
      .text {
        font-size: 12px;
      }
    }
    .descrble {
      font-size: 12px;
      text-align: center;
      padding-top: 15px;
    }
  }
}
</style>

<style lang="scss">
.header-info-popup {
  // 用户名称输入框
  .user-name {
    .el-input {
      .el-input__wrapper {
        box-shadow: none;

        .el-input__inner {
          text-align: center !important;
          font-weight: 600;
          color: #000000;
        }
      }
    }
  }
}
</style>
